<template>
	<section :key="role">
		<div class="center">
			<table>
				<tr>
					<th class="theme_text" v-if="role == 'donor'">姓名</th>
					<th class="theme_text">信箱</th>
					<th class="theme_text" v-if="role == 'donor'">年度捐款金額</th>
					<th class="theme_text" v-if="role == 'donor'">捐款幾年</th>
					<th class="theme_text">訂閱電子報</th>
					<th></th>
				</tr>
				<tr v-for="(user, i) in _list" :key="i">
					<td v-if="role == 'donor'">{{user.name}}{{"\u3000"}}<small style="color:#a3a6b4;">{{user.gender}}</small></td>
					<td>{{user.email}}</td>
					<td v-if="role == 'donor'">{{user.amount_per_year | currencyFilter}}</td>
					<td v-if="role == 'donor'">{{user.donation_years}}</td>
					<td>
						<el-tag v-if="user.subscribe" type="success">是</el-tag>
						<el-tag v-else type="info">否</el-tag>
					</td>
					<td>
						<el-button @click="handleReview(user)" type="primary" icon="el-icon-user" size="mini">詳細</el-button>
					</td>
				</tr>
				<tr v-if="!_list.length">
					<td colspan="6">尚無資料</td>
				</tr>
			</table>
		</div>
		<el-dialog :visible.sync="dialogVisible">
			<el-form label-width="110px" label-position="left" label-suffix="：">
				<el-form-item label="信箱" class="underline">
					{{ruleForm.email}}
				</el-form-item>
				<el-form-item label="姓名" class="underline">
					{{ruleForm.name}}
					<small>{{ruleForm.gender}}</small>
				</el-form-item>
				<el-form-item label="生日" class="underline">
					{{ruleForm.birthday | yearFilter}}
				</el-form-item>
				<el-form-item label="手機" class="underline">
					{{ruleForm.cellphone}}
				</el-form-item>
				<el-form-item label="地址" class="underline">
					{{ruleForm.zipcode}}
					{{ruleForm.city}}
					{{ruleForm.district}}
					{{ruleForm.address}}
				</el-form-item>
				<template v-if="role == 'donor'">
					<el-form-item label="抬頭" class="underline">
						{{ruleForm.title}}{{"\u3000"}}
						<el-tag v-if="ruleForm.anonymous" type="danger" size="mini">匿名</el-tag>
					</el-form-item>
					<el-form-item label="身分證／統編" class="underline">
						{{ruleForm.identity}}
					</el-form-item>
					<el-form-item label="年度捐款金額" class="underline">
						<template v-if="ruleForm.amount_per_year">{{ruleForm.amount_per_year | currencyFilter}}</template>
					</el-form-item>
					<el-form-item label="捐款幾年" class="underline">
						<template v-if="ruleForm.donation_years">{{ruleForm.donation_years}}</template>
					</el-form-item>
				</template>
				<el-form-item label="訂閱電子報" class="underline">
					<el-tag v-if="ruleForm.subscribe" type="success">是</el-tag>
					<el-tag v-else type="info">否</el-tag>
				</el-form-item>
				<el-form-item label="註冊日期" class="underline">
					{{ruleForm.create_time | dateFilter}}
				</el-form-item>
			</el-form>
			<div v-if="role == 'donor'" slot="footer" class="center">
				<el-button type="danger" @click="confirmDialogVisible = true">取消椅仔會員</el-button>
			</div>
		</el-dialog>
		<el-dialog :visible="confirmDialogVisible" width="500px">
			<template slot="title">請輸入原因？</template>
			<el-input v-model="denyReason" type="textarea" :autosize="{minRows:3}" />
			<div slot="footer" class="row" align-x="right">
				<el-button @click="confirmDialogVisible = false" type="info" size="mini">取消</el-button>
				<el-button @click="handleApplyError(ruleForm)" type="primary" size="mini">確認</el-button>
			</div>
		</el-dialog>
	</section>
</template>

<script>
import { mapState, mapActions } from "vuex"
export default {
	props: ["role"],
	data() {
		return {
			dialogVisible: false,
			confirmDialogVisible: false,
			ruleForm: new Object,
			denyReason: ""
		}
	},
	computed: {
		...mapState("auth", ["_list"]),
	},
	watch: {
		role(val) {
			this._getList(this.role);
		},
		confirmDialogVisible(val) {
			this.denyReason = "";
		}
	},
	methods: {
		...mapActions("auth", ["_getList", "_handleMemberStatus"]),
		handleReview(data) {
			this.ruleForm = data;
			this.dialogVisible = true;
		},
		async handleApplyError({ id, name }) {
			this.confirmDialogVisible = true;
			this.$message.success(
				await this._handleMemberStatus({
					id,
					data: {
						action: "denied",
						message: this.denyReason
					}
				})
			)
			this.confirmDialogVisible = false;
			this.dialogVisible = false;
		},
	},
	created() {
		this._getList(this.role);
	}
}
</script>

<style lang="scss" scoped>
table {
	background: #fff;
	border-radius: 10px;
	text-align: center;
}
tr:nth-child(even) {
	background: $theme-background;
}
th,
td {
	padding: 1em 2em;
}
</style>